<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      New Route
    </hero-bar>
    <section class="section is-main-section">
      <form @submit.prevent="submit">
        <card-component title="Route Details" icon="road-variant">
          <div class="columns">
            <div class="column">
              <b-field label="Route Name" expanded>
                <b-input
                  minlength="3"
                  v-model="route.name"
                  name="routeName"
                  required
                />
              </b-field>
            </div>
          </div>
        </card-component>
        <card-component title="Customers" icon="account-group">
          <b-table
            :data="customers"
            v-if="customers.length"
            hoverable
            draggable
            @dragstart="dragstart"
            @drop="drop"
            @dragover="dragover"
            @dragleave="dragleave"
          >
            <b-table-column field="name" label="Customer Name" v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column field="address" label="Address" v-slot="props">
                {{ props.row.address }}
            </b-table-column>

            <b-table-column field="villageCode" label="Village" v-slot="props">
                {{ customerVillagesMapping[props.row.villageCode] || props.row.villageCode}}
            </b-table-column>

            <b-table-column field="statusCode" label="Status Code" centered v-slot="props">
                {{ customerStatusesMapping[props.row.statusCode] || props.row.statusCode }}
            </b-table-column>

            <b-table-column field="numberOfBins" label="Count of bin/s" centered v-slot="props">
                {{ props.row.bins.length }}
            </b-table-column>

            <b-table-column field="action" label="Action" v-slot="props" centered>
              <b-button type="is-danger" size="is-small" @click="remove(props.index)">Remove</b-button>
            </b-table-column>
          </b-table>
          <div class="content has-text-grey has-text-centered" v-else>
            <p>
              <b-icon icon="playlist-remove" size="is-large" />
            </p>
            <p>No customers. Click Add Customers to add.</p>
          </div>
          <hr />
          <b-field grouped position="is-right">
            <div class="control">
              <b-button @click="exit"
                >Cancel</b-button
              >
            </div>
            <div class="control">
              <b-button type="is-primary is-outlined" @click="addCustomers"
                >Add Customers</b-button
              >
            </div>
            <div class="control">
              <b-button native-type="submit" type="is-primary" :disabled="!infoHasChanges"
                >Submit</b-button
              >
            </div>
          </b-field>
        </card-component>
      </form>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import route from '@/services/route'

import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import AddRouteCustomers from '@/modals/AddRouteCustomers'

export default {
  name: 'CreateRoute',
  components: {
    TitleBar,
    HeroBar,
    CardComponent
  },
  data () {
    return {
      route: {},
      customers: []
    }
  },
  methods: {
    submit () {
      this.createCustomer()
    },
    async createCustomer () {
      try {
        const data = { name: this.route.name }
        if (this.customers.length) {
          data.customers = this.customers.map(({ id }) => id)
        }
        await route.createRoute(data)
        this.$buefy.snackbar.open({
          message: 'Route created successfully',
          queue: false
        })
        this.$router.go(-1)
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          queue: false
        })
      }
    },
    addCustomers () {
      this.$buefy.modal.open({
        component: AddRouteCustomers,
        parent: this,
        props: {
          customersData: [...this.customers]
        },
        events: {
          onCustomersAdd: ({ customers, close }) => {
            this.customers = [...customers]
            this.$buefy.toast.open('Customers added successfully')
            close()
          }
        }
      })
    },
    remove (idx) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to remove customer?',
        onConfirm: () => {
          const tempCopy = [...this.customers]
          tempCopy.splice(idx, 1)
          this.customers = tempCopy
          this.$buefy.toast.open('Customer removed successfully')
        }
      })
    },
    exit () {
      // check if there is data added
      if (this.infoHasChanges) {
        this.$buefy.dialog.confirm({
          message: 'Information will not be saved. Are you sure you want to exit?',
          onConfirm: () => {
            this.$router.go(-1)
          }
        })
      } else {
        this.$router.go(-1)
      }
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      // payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      const droppedOnRowIndex = payload.index

      if (droppedOnRowIndex === this.draggingRowIndex) return

      payload.event.target.closest('tr').classList.remove('is-selected')
      const temp = [...this.customers]
      // remove it first from the index;
      temp.splice(this.draggingRowIndex, 1)
      temp.splice(droppedOnRowIndex, 0, this.customers[this.draggingRowIndex])
      this.customers = temp
      this.orderChanged = true

      this.$buefy.toast.open(`Moved ${this.draggingRow.name} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)
    }
  },
  computed: {
    ...mapGetters('lookups', [
      'customerStatusesMapping',
      'customerVillagesMapping'
    ]),
    infoHasChanges () {
      return this.route.name || this.customers.length > 0
    },
    titleStack () {
      return ['Route', 'New']
    }
  }
}
</script>
